<template>
  <div>
    <div class="info mb-0 p-3 d-flex flex-column h2 shadow">
      <b-row v-if="cart.length">
        <b-col v-if="productInventoryMode === false" class="mb-1" cols="12">
          <h1 v-if="restockMode === true" class="text-center">
            Comprar a proveedor {{ currentRestockSupplier.name }}
          </h1>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            Descuento:
            <span
              >$ {{ (cartTotalNoPromotionApplied - cartTotal) | money }}</span
            >
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            Subtotal:
            <span>$ {{ (cartTotal - cartTotal * 0.16) | money }}</span>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            IVA: <span>$ {{ (cartTotal * 0.16) | money }}</span>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-success
              mt-50
              responsive-text
            "
          >
            Total: <span>$ {{ cartTotal | money }}</span>
          </div>
        </b-col>
      </b-row>
      <div v-else class="d-flex justify-content-center">
        <b-img
          :src="require('@/assets/images/logo/walleat_logo_white.svg')"
          fluid
          alt="Responsive image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("pos", [
      "cartTotal",
      "cartTotalNoPromotionApplied",
      "cart",
      "restockMode",
      "productInventoryMode",
      "currentRestockSupplier",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.info {
  border-radius: 10px 10px 10px 10px;
}

.dark-layout {
  .info {
    background-color: #0e1322;
  }
}

@media (max-width: 640px) {
  .payment {
    padding: 4px !important;

    button {
      padding: 0;

      img {
        width: 50px !important;
      }

      h5 {
        font-size: 10px;
      }
    }
  }

  .responsive-text {
    font-size: 1rem !important;
  }

  .info {
    padding: 14px !important;
  }
}
</style>
